import React, { useEffect, useRef } from "react";

function AdBanner() {
    const adLoaded = useRef(false);

    useEffect(() => {
        if (!adLoaded.current) {
            try {
                (window.adsbygoogle = window.adsbygoogle || []).push({});
                adLoaded.current = true;
            } catch (e) {
                console.error(e);
            }
        }
    }, []);

    return (
        <ins
            className="adsbygoogle"
            style={{ display: "block" }}
            data-ad-client="ca-pub-XXXXXX"
            data-ad-slot="YYYYYY"
            data-ad-format="auto"
            data-full-width-responsive="true"
            // data-adtest="off"
        ></ins>
    );
}

export default AdBanner;
