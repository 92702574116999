import React, { useState, useEffect } from "react";
import axios from "axios";
import AdBanner from "./components/AdBanner";
import Rays from "./components/Rays";
import "./App.css";

// Helper function to format a given timestamp into a human-readable time string
const formatTime = (timestamp) =>
    new Date(timestamp).toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false, // Uses 24-hour format
    });

// Helper function to retrieve stored locked word data from localStorage
const getStoredLockedData = () => {
    const stored = localStorage.getItem("lockedData");
    if (!stored) return null; // If no data is found, return null

    const parsed = JSON.parse(stored); // Parse JSON data
    return parsed.expiry && new Date(parsed.expiry) > new Date() ? parsed : null;
    // If expiry time is in the future, return the data; otherwise, return null
};
//------------------------------------------------------------------------------
function App() {
    // State to store the locked word data (word, definition, expiry time)
    const [lockedData, setLockedData] = useState(null);
    // State to track loading status while fetching data
    const [loading, setLoading] = useState(false);
    // API URL retrieved from environment variables
    const API_URL = process.env.REACT_APP_API_URL || "";

    // useEffect runs once when the component mounts, checking for stored locked data
    useEffect(() => {
        const storedData = getStoredLockedData(); // Retrieve stored word data
        if (storedData) setLockedData(storedData); // If valid, update state
    }, []); // Empty dependency array ensures this runs only on mount

    // Boolean to check if a word is currently locked
    const isLocked = !!lockedData;
    // Compute the formatted lock time if expiry exists
    const lockTime = lockedData?.expiry ? formatTime(lockedData.expiry) : "";

    // function to check if tomorrow is reached
    const isTomorrow = () => {
        if (!lockedData?.expiry) return false;

        const expiryDate = new Date(lockedData.expiry);
        const midnightTonight = new Date();
        midnightTonight.setHours(24, 0, 0, 0); // Midnight tonight (start of tomorrow)

        return expiryDate >= midnightTonight;
    };
    // Function to fetch a new random word from the API
    const fetchWord = async () => {
        try {
            setLoading(true); // Set loading to true while fetching
            const { data } = await axios.get(`${API_URL}/api/random-word`);

            // Determine the expiration time for the locked word (default 24 hours)
            const lockSeconds = parseInt(process.env.REACT_APP_LOCK_SECONDS, 10) || 86400;
            const expiryDate = new Date(Date.now() + lockSeconds * 1000).toISOString();

            // Create a new data object with the fetched word, definition, and expiry time
            const newData = {
                word: data.word,
                definition: data.definition,
                expiry: expiryDate,
            };

            // Update state and store the locked word in localStorage
            setLockedData(newData);
            localStorage.setItem("lockedData", JSON.stringify(newData));
        } catch (error) {
            console.error("Error fetching word:", error);
        } finally {
            setLoading(false); // Reset loading state
        }
    };
    //------------------------------------------------------------------------------
    return (
        <div className="container">
            <h1>HigherGuide</h1>

            {/* If a word is locked, display the locked word UI */}
            {isLocked ? (
                <>
                    <div className="rayContainer">
                        <Rays className="ray" />
                    </div>
                    <div className="word-box">
                        <h2 className="word">{lockedData.word}</h2>
                        <p className="definition">{lockedData.definition}</p>
                        <p className="expiry-text">{`(This is yours till ${lockTime} ${
                            isTomorrow() ? "tomorrow" : ""
                        })`}</p>{" "}
                    </div>
                </>
            ) : (
                // If no word is locked, show the introduction and fetch button
                <div className={`intro ${loading ? "fadeOut" : ""}`}>
                    <p>
                        Welcome! Take a moment to focus on your intention: a question, a feeling, or a situation where
                        you seek clarity. When you're ready, press the button, and a word meant only for you will
                        appear. This word is yours for the next 24 hours. Its meaning is personal and for just you to
                        interpret. Trust the universe process, reflect on it, and let it guide you. ✨
                    </p>
                    <button className="showBtn" onClick={fetchWord} disabled={loading}>
                        Show Me
                    </button>
                </div>
            )}

            {/* Advertisement banner component */}
            <AdBanner />
        </div>
    );
}

export default App;
